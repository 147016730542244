<template>
  <div class="redbar">
    <img class="polkalogo" src="https://utils.polka.ph/v4backend/photos/logo-white.png">
  </div>
  <br>
    <br>
    <br>
    <br>
  <div class="white-section">
    <p style="line-height: 0.6;margin-top: 50px; text-align: center; font-size: 60px;">❌</p>
    <p style=" text-align: center;">Payment Failed. Please contact our client support for further concerns.</p>
  </div>  
</template>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: Jost,Helvetica,Arial,sans-serif;
    font-size: 18px;
}

.redbar {
  height: 60px;
  background-color: #ff004a;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

body {
  background-color: #FFF1F2;
  padding: 0;
  margin:0;
}

.polkalogo {
  margin-left:16px;
  height: 30px;
  margin-top:15px;
}

.white-section {
  background-color: white;
  margin: 80px max(calc(50% - 405px), 20px) 60px;
  border-radius: 20px;
  padding: 20px 20px 40px;
  box-shadow: 0 3px 9px rgb(0 0 0 / 10%);
  max-width: 810px;
  
}

</style>
