<template>
  
  <iframe v-show="loaded" :src="srclink" :style="{minWidth:'100%', minHeight:'100vh'}" @load="loaded = true"></iframe>
  <div v-if="loaded == false" class="vh-100 vw-100 d-flex justify-content-center">
    <faIcon class="align-self-center mb-5 fs-1" icon="spinner" spin />
  </div>
</template>

<script>
export default {
  name: 'embedFrame',
  props: {
    srclink: {},
  },
  data(){
    return {
      loaded: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
