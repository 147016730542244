import { createWebHistory, createRouter } from "vue-router";
import Frame from "@/views/frame.vue";
import smAppShortUrl from '@/views/smAppShortUrl'
import trdCheckout from '@/views/3pCheckout'
import trdMain from '@/views/3pMain'
import trdCheckoutomplete from '@/views/3pLink200'
import linkExpired from '@/views/linkExpired'
import linkFail from '@/views/linkFail.vue'

const routes = [
  {
    path: "/pay",
    component: Frame,
    props:  route => ({srclink : copyquery("https://form.jotform.com/221705513400441", route.query) })
  },
  {
    path: "/invite",
    component: Frame,
    props:  route => ({srclink : copyquery("https://form.jotform.com/201322424936449", route.query) })
  },
  {
    path: "/quote",
    component: Frame,
    props:  route => ({srclink : copyquery("https://form.jotform.com/201585249080455", route.query) })
  },
  {
    path: "/sign",
    component: Frame,
    props:  route => ({srclink : copyquery("https://form.jotform.com/203088098213455", route.query) })
  },
  {
    path: "/checkout",
    component: Frame,
    props:  route => ({srclink : copyquery("https://form.jotform.com/210434462690452", route.query) })
  },
  {
    path: "/sm/:shorturl",
    component: smAppShortUrl
  },
  {
    path: "/3p-kiosk/:recid",
    component: trdCheckout
  },
  {
    path: "/3p-pay/:recid",
    component: trdMain
  },
  {
    path: "/3p-complete/:recid",
    component: trdCheckoutomplete
  },
  {
    path: "/link-expired",
    component: linkExpired

  },
  {
    path: "/link-failed",
    component: linkFail

  }

  
];

function copyquery(url,qobj){
  let keys = Object.keys(qobj)
  let ret = url + "?"
  keys.forEach((key) => {
    ret += key + "=" + qobj[key] + "&"
  })
  if(ret.charAt(ret.length - 1)  == '?' || ret.charAt(ret.length - 1)  == '&') {
    return ret.slice(0, -1)
  } else{
    return ret
  }
}



const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;