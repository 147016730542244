<template>
  
  <iframe v-show="srclink && loaded" :src="srclink" :style="{minWidth:'100%', minHeight:'100vh'}" @load="loaded = true"></iframe>
  <div v-if="loaded == false" class="vh-100 vw-100 d-flex justify-content-center">
    <faIcon class="align-self-center mb-5 fs-1" icon="spinner" spin />
  </div>
</template>

<script setup>

import {ref} from 'vue'
import { useRoute } from 'vue-router';
import axios from 'axios'

const route = useRoute()

// eslint-disable-next-line no-unused-vars
const srclink = ref(false)

const loaded = ref(false)

axios.post("https://utils.polka.ph/v4backend/urlShorter.php", {statement: "get-by-id", id: route.params.shorturl}).then( (res) =>{
  if(res.data.status == 'success' && Array.isArray(res.data.response) ){
    if(res.data.response.length > 0){
      console.log()
      srclink.value = res.data.response[0].url
    } else {
      //no rows invalid url
      console.log('now rows')
    }
  }

}).catch( () => {
  console.log('error')
  //window.location.href = "http://www.w3schools.com";
})






</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
