<template>
<!-- Messenger Chat Plugin Code -->
<div>
  <div class="redbar">
    <img class="polkalogo" src="https://utils.polka.ph/v4backend/photos/logo-white.png">
  </div>
  <div class="white-section">
    <div style="margin-bottom: 24px; black;">
      <table style="width: 100%;">
        <tr>
          <td style="width: 56px">
            <img src="https://photos.polka.ph/icons/red-check-circle-v6.png" style="width: 56px; display: inline-block;">
          </td>
          <td style="vertical-align: middle;padding-left: 12px;">
            <span style="font-size: 22px; top: 50%;  line-height: 1; font-weight: 600;">Thank you, {{fname}}!</span>
            <br>
            <span style="font-size: 18px; top: 50%;  line-height: 1; color: #666;">We are getting your order ready.</span>
          </td>
        </tr>
      </table>
    </div>
    <div class="half2" >
      <div>
        <table style="font-size:18px;width:100%;">
          <tbody>
          <tr>
              <td style="padding-bottom: 18px; width:70%;vertical-align:middle;  border-bottom: 1px solid black;">
                <span>Order ID</span>
              </td>
              <td style="padding-bottom: 18px; width:30%;text-align:right;vertical-align:middle; border-bottom: 1px solid black;">
                <span style="font-weight: 600;padding:0px;color:#ff004a">{{ cxOrderNum }}</span>
              </td>
            </tr>
            <tr>
              <td style="padding-top: 18px; width:70%;vertical-align:middle">
                  <span>1× {{ prod }}</span>
              </td>
              <td style="padding-top: 18px; font-weight: 600; width:30%;text-align:right;vertical-align:middle">
                  <span>₱{{ SRP }}</span>
              </td>
            </tr>
            <tr>
              <td style="padding-bottom: 18px; width:70%;vertical-align:middle;  border-bottom: 1px solid black;">
                <span>Shipping Fee</span>
              </td>
              <td style="padding-bottom: 18px; width:30%;text-align:right;vertical-align:middle; border-bottom: 1px solid black;">
                <span v-if="shipMeth === 'pickup'" style="font-weight: 600;padding:0px;color:#000000">Free ❤️</span>
                <span v-else-if="shipMeth === 'delivery'" style="font-weight: 600;padding:0px;color:#000000">₱100.00</span>
              </td>
            </tr>         
          </tbody>
        </table>
        <table class="sticky2" style="font-size:18px;width:100%; margin-right: 12px;">
          <tr>
            <td style="padding: 18px 0;  width:70%;vertical-align:middle;">
              <span>Total Price</span>
            </td>
            <td style="padding: 18px 0; font-weight: 600; width:30%;text-align:right;vertical-align:middle;">
              <span style="color:#000000">₱{{ total }}</span>
            </td>
          </tr>
        </table>
        
      </div>
    </div>

    <div class="half2">
      <div class="lead-time">  
        <span><strong>Delivery Lead Time:</strong></span>
        <ul>
          <li>In-Store Pick-Up: 2–5 working days</li>
          <li>NCR Home Delivery: 2–5 working days</li>
          <li>Provincial Home Delivery: 1–2 weeks</li>
        </ul>  
      </div>
    </div>
    <p><strong>Customer Information:</strong></p>

    <table id="contact-details">
      <tr>
        <td>Contact Number</td>
        <td>+{{ ContactNum }}</td>
      </tr>
      <tr>
        <td>Shipping Details</td>
        <td>{{ shipName }}
          <br>
          +63{{shipNum}}
          <br>
          {{ shipAdd }}
        </td>
      </tr>
      <tr>
        <td>Shipping Method</td>
        <td v-if="shipMeth === 'pickup'">In-Store Pick-up</td>
        <td v-else-if="shipMeth === 'delivery'">Home Delivery</td>
        <td v-else> Not Specified</td>
      </tr>
      <tr>
        <td>Payment Method</td>
        <td v-if="payMeth === 'Billease'">Billease</td>
        <td v-else-if="payMeth === 'Xendit'">Card/E-wallet/Online Banking/OTC</td>
        <td v-else> Not Specified</td>
      </tr>
      <tr></tr>
    </table>

    <p style="padding-top: 24px; border-top: 1px solid black;"><strong>While You're Here:</strong></p>
    <div class="flex-container">
      <!--a target="_blank" href="https:/shop.polka.ph" class="button red-button" >Continue Shopping</a-->
      <a target="_blank" href="https://m.me/Shop.Polka.PH" class="button red-button" >Chat With Us</a>
      <a target="_blank" href="https://www.facebook.com/Shop.Polka.PH" class="button blue-button" >Follow Us on Social</a>
      <a target="_blank" href="https://www.polka.ph/business" class="button yellow-button" >POLKA for Business</a>
    </div>
  </div> 

</div>
</template>


<script setup>
import { onMounted, ref, computed, reactive } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import accounting from 'accounting-js'


const route = useRoute()

const transRec= ref("")
const orderData = reactive({rec: false})



const cxOrderNum = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("SM Order Code" in orderData.rec.fields){
    return "SM-" + orderData.rec.fields["SM Order Code"]
  } else{
    return "#37000" + orderData.rec.fields["Auto"]
  }
})


const fname = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("First Name" in orderData.rec.fields){
    return  orderData.rec.fields["First Name"]
  } else{
    return "Customer"
  }
})

const lname = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("Last Name" in orderData.rec.fields){
    return  orderData.rec.fields["Last Name"]
  } else{
    return "Customer"
  }
})

const prod = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("Product Description" in orderData.rec.fields){
    return  orderData.rec.fields["Product Description"]
  } else{
    return "-"
  }
})


const SRP = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("SRP" in orderData.rec.fields){
    return  accounting.formatMoney(orderData.rec.fields.SRP , {symbol : "", decimal : ".",	thousand: ",",	precision: 2, format: "%s%v"})
  } else{
    return "0.00"
  }
})

const shipMeth = computed(() =>{
  if(orderData.rec === false){
    return false
  } else if("Shipping Method" in orderData.rec.fields){
    return  orderData.rec.fields["Shipping Method"]
  } else{
    return false
  }
})


const shipNum = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("Shipping Contact" in orderData.rec.fields){
    return  orderData.rec.fields["Shipping Contact"]
  } else{
    return "Unknown"
  }
})

const shipName = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("Shipping Name" in orderData.rec.fields){
    return  orderData.rec.fields["Shipping Name"]
  } else{
    return fname.value + " " + lname.value
  }
})

const ContactNum = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("Mobile Number Final" in orderData.rec.fields){
    return  orderData.rec.fields["Mobile Number Final"]
  } else{
    return "Unknown"
  }
})

const shipAdd = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("Shipping Address" in orderData.rec.fields){
    return  orderData.rec.fields["Shipping Address"]
  } else{
    return "Unknown"
  }
})

const payMeth = computed(() =>{
  if(orderData.rec === false){
    return false
  } else if("Payment Method" in orderData.rec.fields){
    return  orderData.rec.fields["Payment Method"]
  } else{
    return false
  }
})



const total = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("SRP" in orderData.rec.fields && shipMeth.value !== false){
    let total = shipMeth.value == 'delivery' ? orderData.rec.fields.SRP + 100 : orderData.rec.fields.SRP
    return accounting.formatMoney(total, {symbol : "", decimal : ".",	thousand: ",",	precision: 2, format: "%s%v"})
  } else{
    return "0.00"
  }
})




onMounted(() => {
  transRec.value = route.params.recid
  axios.post("https://utils.polka.ph/v4backend/airtable-api.php", {statement: "kiosk-order-update", payload: [{recID: route.params.recid, "Payment Status": "Completed"}] }).then( (res) =>{
    console.log(res)
    if(res.data.code < 201){
      orderData.rec = res.data.response[0]
    }
  })
})
  
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	font-family: Jost,Helvetica,Arial,sans-serif;
  font-size: 18px;
}
.redbar {
  height: 60px;
  background-color: #ff004a;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

.sticky2 {
  background-color: white;

}

#mobile-container::before {
	content: "+63";
  position: absolute;
  padding: 0 12px;
  display:inline-block;
  vertical-align:middle;
  line-height: 40px;
  height: 40px;
  background-color: #EEEEEE;
  border-radius: 50px 0 0 50px;
  border: 1px solid black;

}

.flex-container {
	display: flex;
	gap: 0.7rem;
}

.flex-container a {
	flex-grow: 1;
  width: 100%;
  padding-bottom: 6px;
  border-radius: 50px;
}

#address-section {
	display:none;
}

.payOption {
	border-radius: 20px;
    border: 2px solid gray;
}


#mobile {
	padding-left: 60px;
}

input {
	height: 28px;
    line-height: 28px;
}

.button {
    width: 100%;
    border-radius: 50px;
	line-height: 28px;
    height: 28px;
    font-size: 16px;
    margin: 10px 0 0;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
}

.red-button {
	background-color: #ff004a;
    color: white;
}

.blue-button {
	background-color:#3769FF;
    color: white;
}

.yellow-button {
	background-color:#FFD66C;
    color: black;
}


select {
  padding: 10px;
  padding-right: 30px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1em;
}

/* For IE (thanks to @SaiManoj) */
select::-ms-expand {
  display: none;
}



table, th, td {
  border-collapse: collapse;
  line-height: 1.6;
}

body {
  background-color: #FFF1F2;
  padding: 0;
  margin:0;
}

.polkalogo {
  margin-left:16px;
  height: 30px;
  margin-top:15px;
}

.white-section {
  background-color: white;
  margin: 80px max(calc(50% - 405px), 20px) 60px;
  border-radius: 20px;
  padding: 20px 20px 40px;
  box-shadow: 0 3px 9px rgb(0 0 0 / 10%);
  max-width: 810px;
}

.half2 {
	width: 49%;
    display:inline-block;
    vertical-align: top;
    height: 100%;

}

.pimage {
	margin-left:-15px;

}

.half2 > div:first-child:not(.lead-time) {
	margin-right: 12px;
}

@media only screen and (max-width: 576px) {

  .white-section {
    border-radius: 0 0 20px 20px;
    margin: 60px 0 60px 0;
  }

  .sticky2 {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    z-index: 30;
}
   
  .flex-container {
    flex-direction: column;
    ;
  }
   
  .half2 > div:first-child:not(.lead-time) {
    margin-right: 0;
  }

 
  .pname {
    margin-top:12px;
  }
 
  .pimage {
    margin-left:0;
  }
 
  .half2 {
    position: -webkit-relative;
    position: relative;
    top: 0;
    width: 100%;
    margin-bottom: 12px;
  }
}



.pimage {
   display: inline-block;
   width: 100%;
}

.pname {
    display: inline-block;
    font-weight: 600;
    font-size: 26px;
}

.lead-time {
	background-color: #FFFCF0;
    border-left: 3px solid #FFD66C;
    padding: 22px 16px 18px;
    border-radius: 0 16px 16px 0;
    margin: 0;
}


ul {
	margin: 0;
    padding-left: 30px;
    line-height: 1.75;
}

li {
	margin: 0.5rem 0;
}

h2 {
	margin: 30px 0 12px;
}



#contact-details td {
	vertical-align: top;
    padding-bottom: 24px;
    padding-top: 12px;

}

#contact-details tr > td:first-child {
	width: 30%;
    padding-right: 12px;
}

#contact-details tr > td:last-child {
	
}

</style>