import { createApp } from 'vue/dist/vue.esm-bundler';
//import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { library } from '@fortawesome/fontawesome-svg-core'

/* import fontawesome icons from solid*/
import { faSpinner, faHourglassHalf} from '@fortawesome/free-solid-svg-icons'

/* import fontawesome icons from regular*/
//import { } from '@fortawesome/free-regular-svg-icons'

library.add(faSpinner, faHourglassHalf)

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


const app = createApp(App)

app.component('faIcon', FontAwesomeIcon)

app.use(router)
app.mount('#app')
