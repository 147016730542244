<template>
<!-- Messenger Chat Plugin Code -->
<div class="bg-pink min-vh-100">
  <div class="redbar">
    <img class="polkalogo" src="https://utils.polka.ph/v4backend/photos/logo-white.png">
  </div>

  <div :style="{height: '60px', width: '100%'}"></div> <!-- spacer-->

  <div class="container py-5">
    <div v-if="orderData.rec" class="bg-white p-4 rounded-15">
      <!-- item list-->
      <div class="py-3 ">Order Number: {{ orderData.rec.fields["Order #"] }}</div>
      <div class="mt-3">
        <div class="d-flex border-bottom border-3 pb-3">
          <div class="px-2">Qty</div>
          <div class="flex-grow-1 px-2">Item</div>
          <div class="px-2">Price</div>
        </div>
        <div class="d-flex border-bottom py-2" v-for="(item, index) in items" :key = index>
          <div class="px-2" :style="{minWidth: '45px'}">{{item.qty}}</div>
          <div class="flex-grow-1 px-2">{{ item.name }}</div>
          <div class="px-2">{{ item.value }}</div>
        </div>
        
        <div v-if="shipFee" class="d-flex py-2">
          <div class="px-2" :style="{minWidth: '45px'}"></div>
          <div class="flex-grow-1 px-2">Shipping</div>
          <div v-if="shipFee == 0" class="px-2">Free</div>
          <div v-else class="px-2">{{ shipFee }}</div>
        </div>

        <div v-if="discount" class="d-flex py-2 border-top">
          <div class="px-2" :style="{minWidth: '45px'}"></div>
          <div class="flex-grow-1 px-2">Discount</div>
          <div v-if="shipFee == 0" class="px-2">Free</div>
          <div v-else class="px-2">-{{ discount }}</div>
        </div>

        <div v-if="totaltoPay" class="d-flex border-top border-2 py-2">
          <div class="px-2" :style="{minWidth: '45px'}"></div>
          <div class="flex-grow-1 px-2">Total:</div>
          <div v-if="shipFee == 0" class="px-2">Free</div>
          <div v-else class="px-2">{{ totaltoPay.str }}</div>
        </div>
      </div>
    </div>
    
    <br>
    
    <div v-show="orderData.rec" class="bg-white p-4 rounded-15">
      <div>Shipping Details</div>
      <div v-if="shipName">{{ shipName }}</div>
      <div v-if="shipNum">+{{ shipNum }}</div>
      <div v-if="shipAdd">{{ shipAdd }}</div>
    </div>

    <div  v-if="orderData.rec" class="bg-white p-4 rounded-15 mt-4">
      <div>Payment Details:</div>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 mt-3 px-2">
          <!-- Billease -->
          <div class="py-2 px-3 planbox" :style="{borderColor:  selected == 'billease' ? '#FF004A' : '#ced4da'}" @click="selected = 'billease'">
            <div class="d-flex fw-5 text-red">
              <div class="flex-grow-1">No Credit Card Required</div>
              <fa v-show="selected == 'billease'" icon="check" class="align-self-center mx-1" /> 
            </div>
            <div class="mt-2 mb-2"> <img :src="backend + 'photos/billease.png'" :style="{height: '3.125rem', width: 'auto'}"/> </div>
            <div class="fw-6">
              Up to 6 months installment
            </div>
            <div class="fw-6">
              34% down payment required
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-xl-4 mt-3 px-2">
          <!-- Home Credit -->
          <div class="py-2 px-3 planbox disabled" :style="{borderColor:  selected == 'homecredit' ? '#FF004A' : '#ced4da'}">
            <div class="d-flex fw-5 text-red">
              <div class="flex-grow-1">Currently Unavailable</div>
              <fa v-show="selected == 'homecredit'" icon="check" class="align-self-center mx-1" /> 
            </div>
            <div class="mt-2 mb-2"> <img :src="backend + 'photos/home-credit.png'" :style="{height: '3.125rem', width: 'auto'}"/> </div>
            <div class="fw-6">
              Up to 12 months* installment
            </div>
            <div class="fw-6">
              30%-40% down required
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-xl-4 mt-3 px-2">
          <!-- BDO -->
          <div class="py-2 px-3 planbox disabled" :style="{borderColor:  selected == 'bdocc' ? '#FF004A' : '#ced4da'}">
            <div class="d-flex fw-5 text-red">
              <div class="flex-grow-1">Comming Soon</div>
              <fa v-show="selected == 'bdocc'" icon="check" class="align-self-center mx-1" /> 
            </div>
            <div class="mt-2 mb-2"> <img :src="backend + 'photos/bdo.png'" :style="{height: '3.125rem', width: 'auto'}"/> </div>
            <div class="fw-6">
              Choose from 3, 6 or 9 months
            </div>
            <div class="fw-6">
              No down payment required
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-xl-4 mt-3 px-2">
          <!-- Pay Now -->
          <div class="py-2 px-3 planbox" :style="{borderColor:  selected == 'paynow' ? '#FF004A' : '#ced4da'}" @click="selected = 'paynow'">
            <div class="d-flex fw-5 text-red">
              <div class="flex-grow-1" :style="{visibility: 'hidden'}">pay now</div>
              <fa v-show="selected == 'paynow'" icon="check" class="align-self-center mx-1" /> 
            </div>
            <div class="fs-3 fw-6 mb-3 mt-2" >Pay Now</div>
            <div class="fw-6">
              <pesoSign/>{{ totaltoPay.str }}
            </div>
            <div class="fw-6" :style="{visibility: 'hidden'}">pay now</div>
          </div>
        </div>
      </div>

      <div class="">
        <button class="btn bg-red text-white mx-auto d-block my-4 px-5 rounded-pill fw-7 fs-4 py-1" :disabled="selected == null" @click="pay">Pay</button>
      </div>
    </div>
  </div>
  
</div>

<loadingMsg v-if="loadingOverlay">
  <div class="p-5 rounded-4 text-white" :style="{backgroundColor : 'rgba(20, 20, 20, 0.8)'}">
    <div class="text-center mb-3"><faIcon class="fs-1" icon="hourglass-half"/></div>
    <div><span class="text-white fs-4">{{ loadingTxt }}</span></div>
  </div>
</loadingMsg>
</template>


<script setup>
// need to ad guards to check if transaction has already been paid for before creating a new link
//billease call back in beta mode.
import { onMounted, ref, reactive, computed } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import accounting from 'accounting-js'
import loadingMsg from '@/components/loadingMsg.vue';

const route = useRoute()
const router = useRouter()
const loadingOverlay = ref (true)
const loadingTxt = ref("Checking Order Details")

const transRec= ref("")
const orderData = reactive({rec: false})
const selected = ref(null)

const backend = "https://utils.polka.ph/v4backend/"

// eslint-disable-next-line no-unused-vars
const items = computed( () =>{
  let arr = []
  if(orderData.rec === false){
    return false
  }

  for (let i = 1; i < 10; i++) {
    if("LI"+ i +"D" in orderData.rec.fields &&  "LI"+ i +"V" in orderData.rec.fields && "LI"+ i +"Q" in orderData.rec.fields){
      arr.push({
        name: orderData.rec.fields["LI"+ i +"D"], 
        value: accounting.formatMoney(orderData.rec.fields["LI"+ i +"V"] , {symbol : "", decimal : ".",	thousand: ",",	precision: 0, format: "%s%v"}), 
        qty: orderData.rec.fields["LI"+ i +"Q"]})  
    } else {
      break
    }
  }

  return arr
})

// eslint-disable-next-line no-unused-vars
const shipFee = computed( () => {
  if(orderData.rec === false){
    return false
  }
  if("Shipping Fee" in orderData.rec.fields){
    return accounting.formatMoney(orderData.rec.fields["Shipping Fee"] , {symbol : "", decimal : ".",	thousand: ",",	precision: 0, format: "%s%v"})
  } else {
    return 0
  }
})

const discount = computed( () => {
  if(orderData.rec === false){
    return false
  }
  if("Sales Discounts, Returns & Allowances" in orderData.rec.fields && orderData.rec.fields["Sales Discounts, Returns & Allowances"] > 0 ){
    return accounting.formatMoney(orderData.rec.fields["Sales Discounts, Returns & Allowances"] , {symbol : "", decimal : ".",	thousand: ",",	precision: 0, format: "%s%v"})
  } else {
    return false
  }
})



// eslint-disable-next-line no-unused-vars
const shipAdd = computed( () => {
  if(orderData.rec === false){
    return false
  }

  if("Shipping Street Address" in orderData.rec.fields){
    return orderData.rec.fields["Shipping Street Address"]
  } else {
    return null
  }
})

// eslint-disable-next-line no-unused-vars
const shipName = computed( () => {
  if(orderData.rec === false){
    return false
  }

  if("Shipping Full Name" in orderData.rec.fields){
    return orderData.rec.fields["Shipping Full Name"]
  } else {
    return null
  }
})

// eslint-disable-next-line no-unused-vars
const shipNum = computed( () => {
  if(orderData.rec === false){
    return false
  }

  if("Shipping Contact Number" in orderData.rec.fields){
    return orderData.rec.fields["Shipping Contact Number"]
  } else {
    return null
  }
})

const totaltoPay = computed(() => {
  if(orderData.rec === false){
    return false
  }
  if("SRP" in orderData.rec.fields){
    let amt = orderData.rec.fields["SRP"] + parseInt(shipFee.value)
    if(discount.value !== false) {amt -= parseInt(discount.value)}
    return {amt:amt, str: accounting.formatMoney(amt , {symbol : "", decimal : ".",	thousand: ",",	precision: 0, format: "%s%v"}) }
  } else{
    return null
  }
})

onMounted(() => {
  transRec.value = route.params.recid
  axios.post(backend + "airtable-api.php", {statement: "get-3pmain-order", recid: route.params.recid}).then( (res) => {
    console.log(res)
    if(res.data.code < 201){
      orderData.rec = res.data.response[0]

      if("Approval Decision" in res.data.response[0].fields){
        router.push("/link-expired")
      }
      
      let type = res.data.response[0].fields["Order Type"] 
      if(type == "BILLEASE"){
        selected.value = 'billease'
      }
    }
  }).finally(() =>{
    loadingOverlay.value = false

  })
})

function pay(){
  loadingTxt.value = "Connecting with Financing Partner"
  loadingOverlay.value = true
  if(selected.value == 'billease'){
    getBilleaseLink()
  }
  else if (selected.value == 'paynow'){
    getXenditLink()
  }
}

// eslint-disable-next-line no-unused-vars
function getBilleaseLink(){ 
  let billeaseData = {
    statement: "checkout",
    data: {
        shop_code: "354d7e1d-3ebf-4103-a924-c77bd76f653d",
        amount: totaltoPay.value.amt,
        currency: "PHP",
        checkout_type: "standard",
        items: [

        ],
        customer: {
            full_name: shipName.value,
            email: orderData.rec.fields["Email Address"],
            phone: shipNum.value, 
            internal_user_id: orderData.rec.fields["Email Address"]
            
        },
        is_async: true,
        url_redirect: "https://shop.polka.ph/checkout-partner",
        order_id: orderData.rec.fields["Order #"].toString(),
        failed_url_redirect: "https://forms.polka.ph/link-failed",
        callbackapi_url: "https://utils.polka.ph/v4backend/3pcheckout/billease-callback.php"
    }
  }

  
  items.value.forEach((item, index) => {
    billeaseData.data.items.push({
      code: index+1,
      item: item.name,
      price: accounting.unformat(item.value),
      quantity: item.qty 
    })
  })

  if( accounting.unformat(shipFee.value) > 0){
    billeaseData.data.items.push({code: "shipping", item: "Shipping Fee", price: accounting.unformat(shipFee.value), quantity: 1})
  }

  if( discount.value !== false ){
    billeaseData.data.items.push({code: "discount", item: "Shipping Fee", price: accounting.unformat(discount.value) * -1, quantity: 1})
  }

  axios.post("https://utils.polka.ph/v4backend/3pcheckout/billease.php", billeaseData).then( (res) =>{
  
    if(res.data.code < 201){
      //console.log(res.data.response)
      checkoutLinkAction(res.data.response.redirect_url)
    }
  }).catch( () => {
    console.log('req error')
    loadingTxt.value = "Unable to connect with financing partner"
  })
}

function checkoutLinkAction(redirect){
  window.location.href = redirect
}

function getXenditLink(){
  let xenditdata = {
    statement: "checkout",
    data:{
      payment_methods: ["CREDIT_CARD", "7ELEVEN", "CEBUANA", "DD_BPI", "DD_UBP", "DD_RCBC", "DD_CHINABANK", "PAYMAYA", "GRABPAY", "GCASH", "SHOPEEPAY"], 
      currency: "PHP",
      external_id : orderData.rec.fields["Order #"].toString(), 
      amount : totaltoPay.value.amt, 
      description : "Xendit Checkout Order " + orderData.rec.id, 
      invoice_duration : 86400, 
      customer:{
        email: orderData.rec.fields["Email Address"], 
        mobile_number: shipNum.value
      },
      success_redirect_url: "https://shop.polka.ph/checkout-partner", 
      failure_redirect_url: "https://forms.polka.ph/link-failed",
      items: [
      
      ],
    }
  }

  items.value.forEach((item) => {
    xenditdata.data.items.push({
      name: item.name,
      price: accounting.unformat(item.value),
      quantity: item.qty 
    })
  })
  
  let fees = []
  if(accounting.unformat(shipFee.value) > 0){
    fees.push({ type: "Shipping Fee", value: accounting.unformat(shipFee.value)})
  }

  if( discount.value !== false ){
    fees.push({ type: "Discount", value: accounting.unformat(discount.value) * -1})
  }

  if(fees.length > 0){
    xenditdata.fees = fees
  }

  axios.post("https://utils.polka.ph/v4backend/3pcheckout/xendit.php", xenditdata).then( (res) =>{
    if(res.data.code < 201){
      //console.log(res.data)
      checkoutLinkAction(res.data.response.invoice_url)
    }
  }).catch( (error) => {
    console.log(error)
    console.log('req error')
    loadingTxt.value = "Unable to connect with financing partner"
  })
}

</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	font-family: Jost,Helvetica,Arial,sans-serif;
  font-size: 18px;
}
.redbar {
  height: 60px;
  background-color: #ff004a;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}


.polkalogo {
  margin-left:16px;
  height: 30px;
  margin-top:15px;
}


.planbox{
  border: solid 3px;
  /*width: 270px;*/
  border-radius: 15px;
}

.planbox:hover{
  cursor: pointer;
}

.disabled{
  opacity: .4;
}

.disabled:hover{
  cursor:not-allowed !important
}

</style>