<template>
  <div class="redbar">
      <img class="polkalogo" src="https://utils.polka.ph/v4backend/photos/logo-white.png">
    </div>
  <div class="top-wrapper">
    <div class="white-section">
      <div class="half-container">
        <img v-if="orderData.rec !== false" class="pimage" :src="orderData.rec.fields['Product Image']">
      </div>
      <div class="half-container">
        <table style="font-size:18px;width:100%">
          <tbody>
            <tr>
              <td style="padding-bottom: 18px; width:70%;vertical-align:middle;  border-bottom: 1px solid black;">
                <span>Order ID</span>
              </td>
              <td style="padding-bottom: 18px; width:30%;text-align:right;vertical-align:middle; border-bottom: 1px solid black;">
                <span style="font-weight: 600;padding:0px;color:#ff004a">{{cxOrderNum}}</span>
              </td>
            </tr>
            <tr>
              <td style="padding-top: 18px; width:70%;vertical-align:middle">
                <span v-if="orderData.rec !== false">1× {{orderData.rec.fields["Product Description"]}}</span>
                <span v-else>-</span>
              </td>
              <td style="padding-top: 18px; font-weight: 600; width:30%;text-align:right;vertical-align:middle">
                <span v-if="orderData.rec !== false">₱{{ SRPstr }}</span>
                <span v-else>₱0.00</span>
              </td>
            </tr>
            <tr>
              <td style="padding-bottom: 18px; width:70%;vertical-align:middle;  border-bottom: 1px solid black;">
                <span>Shipping Fee</span>
              </td>
              <td style="padding-bottom: 18px; width:30%;text-align:right;vertical-align:middle; border-bottom: 1px solid black;">
                <span v-if="shipMeth == 'pickup'" style="font-weight: 600;padding:0px;color:#000000">Free ❤️</span>
                <span v-else style="font-weight: 600;padding:0px;color:#000000">₱100.00</span>
              </td>
            </tr>         
          </tbody>
        </table>
        <table class="sticky-total" style="font-size:18px;width:100%">
          <tr>
            <td style="padding: 18px 0;  width:70%;vertical-align:middle;">
              <span>Total Price</span>
            </td>
            <td style="padding: 18px 0; font-weight: 600; width:30%;text-align:right;vertical-align:middle;">
              <span style="color:#000000">₱{{totalStr}}</span>
            </td>
          </tr>
        </table>
      
        <h2>Shipping Information:</h2>
        <form style="margin-top: 12px;" id="3p-form" @submit.prevent="formsubmit">
          <div class="flex-container">
            <input required type="text" placeholder="First Name" class="input-short"  v-model="fname">
            <input required type="text" placeholder="Last Name" class="input-short"  v-model="lname">
          </div>
          <div class="flex-container" id="mobile-container">
            <input required type="text" placeholder="Mobile Number (ex.917...)" class="input-long" id="mobile" pattern="[9][0-9]{9}" v-model="num">
          </div>    
            <select required  form="3p-form" style="margin-bottom: 1.1rem; width: 100%; border:1px solid black; border-radius: 50px; padding: 6px 15px; height: 40px;" id="deliveryMethod" class="bg-white" v-model="shipMeth">
              <option value="pickup">In-Store Pick-Up</option>
              <option value="delivery">Home Delivery</option>
            </select>
          <div id="address-section">
            <div class="flex-container">
              <input required id="street" type="text" placeholder="Street Address" class="input-long" :readonly="shipMeth == 'pickup'" v-model="street" :style="{backgroundColor: shipMeth == 'pickup' ? '#F6F6F6' : 'white'}">
            </div>
            <div class="flex-container">
              <input required id="barangay" type="text" placeholder="Barangay" class="input-short" :readonly="shipMeth == 'pickup'" v-model="brgy" :style="{backgroundColor: shipMeth == 'pickup' ? '#F6F6F6' : 'white'}">
              <input required id="city" type="text" placeholder="City" class="input-short" :readonly="shipMeth == 'pickup'" v-model="city" :style="{backgroundColor: shipMeth == 'pickup' ? '#F6F6F6' : 'white'}">
            </div>
            <div class="flex-container">
              <input required id="province" type="text" placeholder="Province" class="input-short" :readonly="shipMeth == 'pickup'" v-model="prov" :style="{backgroundColor: shipMeth == 'pickup' ? '#F6F6F6' : 'white'}">
              <input required id="zipcode" type="text" placeholder="Zip Code" class="input-short" pattern="^\d{4}$" :readonly="shipMeth == 'pickup'" v-model="zip" :style="{backgroundColor: shipMeth == 'pickup' ? '#F6F6F6' : 'white'}">
            </div>
            <div class="flex-container">
              <input required id="landmark" type="text" placeholder="Landmark/Directions" class="input-long" :readonly="shipMeth == 'pickup'" v-model="notes" :style="{backgroundColor: shipMeth == 'pickup' ? '#F6F6F6' : 'white'}">
            </div>
            <p style="font-size: 16px;"><span style="color:#ff004a;">*</span> POLKA.PH's only physical retail store for pick-up is at SM North EDSA in Quezon City. Please select Home Delivery if you are too far away.</p>
          </div>
        
          <h2>Payment Method:</h2>
          <label class="payOptionsContainer">
            <div class="payHeadline">No Credit Card Needed</div>
            <img src="https://photos.polka.ph/logos/billease-logo.png" class="payImage">
            <input type="radio"  name="radio" value="billease" v-model="payMeth">
            <span class="checkmark"></span>
            <ul>
              <li>6 to 12 months* installment</li>
              <li>34% minimum down payment</li>
              <li>Government ID required</li>
            </ul>
          </label>
          <label class="payOptionsContainer">
            <div class="payHeadline">Straight Payment</div>
            <div style="font-size: 36px; font-weight: 600; margin: 6px 0 18px;">Pay Now</div>
            <img src="https://photos.polka.ph/logos/payment-methods-v3.png" style="width: 100%; max-width: 260px; max-height: 170px; margin-bottom: 8px;">
            <input type="radio" name="radio" value="paynow" v-model="payMeth">
            <span class="checkmark"></span>
          </label>
        
          <div class="notice">  
            <h2 style="margin-top: 0;">Remember:</h2>
            <ul>
              <li>Additional interest and processing fees from the installment provider may apply.</li>
              <li>Possible terms and and approval subject to credit evaluation.</li>
              <li>Proof of Work, Income & Billing may be required for some.</li>
            </ul>
            <h2>Delivery Lead Time:</h2>
            <ul>
              <li>In-Store Pick-Up: 2–5 working days</li>
              <li>NCR Home Delivery: 2–5 working days</li>
              <li>Provincial Home Delivery: 1–2 weeks</li>
            </ul>
          </div>
          
          <label class="agree-container">I have read and understood the terms above and I confirm the information I have provided above is true and correct.
            <input type="checkbox"  required>
            <span class="checkBox"></span>
          </label>
          
          <input type="submit" class="submit-button" value="Go to Payment">
        </form>
      </div>
    </div>
  </div>
  <loadingMsg v-if="loading">
    <div class="rounded-5 p-5 text-center" :style="{backgroundColor: 'rgba(100, 100, 100, .9)'}">
      <div class="text-white fs-5">
        {{ loading }}
      </div>
    </div>
  </loadingMsg>
</template>


<script setup>
import {reactive, onMounted, ref, watch, computed} from 'vue'
import { useRoute } from 'vue-router';
import axios from 'axios'
import loadingMsg from '@/components/loadingMsg.vue'

import accounting from 'accounting-js'


const route = useRoute()
const orderData = reactive({rec: false})
//console.log(route.params.recid)

const successUrl = computed(() => {
  if(transRec.value !== null){
    return "https://forms.polka.ph//3p-complete/" + transRec.value
  } else {
    return "https://forms.polka.ph//3p-complete/generic"
  }
}) 

const failUrl = computed(() => {
  if(transRec.value !== null){
    return "https://forms.polka.ph//3p-complete/" + transRec.value
  } else {
    return "https://forms.polka.ph//3p-fail/generic"
  }
}) 

const transRec = ref(null)
const shipMeth = ref("pickup")

const fname = ref("")
const lname = ref("")
const num = ref("")

const street = ref("5th Floor SM North EDSA Annex Cyberzone")
const brgy = ref("Santo Cristo")
const city = ref("Quezon City")
const prov = ref("Metro Manila")
const zip = ref("1105")
const notes =ref("POLKA.PH near OCTAGON")


const payMeth = ref("billease")

const loading = ref('Checking Order Information....')

const SRPstr = computed( () => {
  return accounting.formatMoney(orderData.rec.fields.SRP , {symbol : "", decimal : ".",	thousand: ",",	precision: 2, format: "%s%v"})
})


const total = computed( ()=>{
  if(orderData.rec === false){
    return 0
  } else if(shipMeth.value == 'pickup'){
    return orderData.rec.fields.SRP
  } else {
    return orderData.rec.fields.SRP + 100
  }
})


const cxOrderNum = computed(() =>{
  if(orderData.rec === false){
    return "-"
  } else if("SM Order Code" in orderData.rec.fields){
    return "SM-" + orderData.rec.fields["SM Order Code"]
  } else{
    return "#37000" + orderData.rec.fields["Auto"]
  }
})

const totalStr = computed( () => {
  return accounting.formatMoney(total.value, {symbol : "", decimal : ".",	thousand: ",",	precision: 2, format: "%s%v"})
})


watch(shipMeth, (val)=>{
  shipmenthChange(val)
})


onMounted(() => {
  shipmenthChange(shipMeth.value)

  axios.post("https://utils.polka.ph/v4backend/airtable-api.php", {statement: "get-kiosk-order", recid: route.params.recid}).then( (res) =>{
  
    if(res.data.code < 201 && Array.isArray(res.data.response) ){
      if(res.data.response.length > 0){
        //console.log(res.data.response[0])

        orderData.rec = res.data.response[0]
        transRec.value = res.data.response[0].id
        num.value = orderData.rec.fields["Mobile Number Final"].substring(2)
        fname.value = orderData.rec.fields["First Name"]
        lname.value = orderData.rec.fields["Last Name"]
        loading.value = false
      } else {
        //no rows invalid url
        console.log('now rows')
      }
    }
  }).catch( () => {
    console.log('error')
  })
})

// eslint-disable-next-line no-unused-vars
function getBilleaseLink(){ 
  let billeaseData = {
    statement: "checkout",
    data: {
        shop_code: "354d7e1d-3ebf-4103-a924-c77bd76f653d",
        amount: total.value,
        currency: "PHP",
        checkout_type: "standard",
        items: [
          {
            code: orderData.rec.fields["Product Code"],
            item: orderData.rec.fields["Product Description"],
            price: orderData.rec.fields.SRP,
            quantity: 1
          }
        ],
        customer: {
            full_name: fname.value + " " + lname.value ,
            email: orderData.rec.fields["Email Address"],
            phone: "+63" + num.value.toString(), 
            internal_user_id: orderData.rec.fields["Email Address"]
            
        },
        is_async: false,
        url_redirect: successUrl.value,
        order_id: transRec.value
    }
  }

  if(shipMeth.value == 'delivery'){
    billeaseData.data.items.push({code: "shipping", item: "Shipping Fee", price: 100, quantity: 1})
  }

  axios.post("https://utils.polka.ph/v4backend/3pcheckout/billease.php", billeaseData).then( (res) =>{
  
    if(res.data.code < 201){
        console.log(res.data.response)
        let toupdate = [{
          recID: transRec.value, 
          "Shipping Method": shipMeth.value, 
          "Payment Method" : "Billease", 
          "Payment Status": "Created", 
          "Shipping Address":  [street.value, brgy.value, city.value, prov.value, zip.value, notes.value].join(", ") , 
          "Shipping Name" : [fname.value, lname.value].join(' ') , 
          "Shipping Contact" : num.value 
        }]        
        updateAirtable(toupdate ,res.data.response.redirect_url)
    }
  }).catch( () => {
    console.log('req error')
  })
}

// eslint-disable-next-line no-unused-vars
function getXenditLink(){
  let xenditdata = {
    statement: "checkout",
    data:{
      payment_methods: ["CREDIT_CARD", "7ELEVEN", "CEBUANA", "DD_BPI", "DD_UBP", "DD_RCBC", "PAYMAYA", "GRABPAY", "GCASH", "SHOPEEPAY"], 
      currency: "PHP",
      external_id : transRec.value, 
      amount : total.value, 
      description : "Xendit Checkout Order " + orderData.rec.id, 
      invoice_duration : 86400, 
      customer:{
        given_names:  fname.value, 
        surname: lname.value, 
        email: orderData.rec.fields["Email Address"], 
        mobile_number: "+64" + num.value.toString()
      },
      success_redirect_url: successUrl.value, 
      failure_redirect_url: failUrl.value,
      items: [
        {
          name : orderData.rec.fields["Product Description"], 
          quantity : 1, 
          price : orderData.rec.fields.SRP, 
        }
      ],
    }
  }

  console.log(xenditdata)
  if(shipMeth.value == 'delivery'){
    xenditdata.data.fees = [{ type: "Shipping Fee", value: 100 }]
  }

  axios.post("https://utils.polka.ph/v4backend/3pcheckout/xendit.php", xenditdata).then( (res) =>{
    if(res.data.code < 201){
      console.log(res.data)
      let toupdate = [{
        recID: transRec.value, 
        "Shipping Method": shipMeth.value, 
        "Payment Method" : 
        "Xendit", 
        "Payment Status": "Created", 
        "Shipping Address":  [street.value, brgy.value, city.value, prov.value, zip.value, notes.value].join(", ") , 
        "Shipping Name" : [fname.value, lname.value].join(' ') , 
        "Shipping Contact" : num.value 
      }]        
      updateAirtable(toupdate , res.data.response.invoice_url)
      //window.location.href = res.data.response.invoice_url;
    }
  }).catch( (error) => {
    console.log(error)
    console.log('req error')
  })
}

function formsubmit(){
  loading.value = "Checking Out... Please wait"
  if(payMeth.value == 'billease'){
    getBilleaseLink()
  } else{
    getXenditLink()
  }
}


function updateAirtable(data, redirect){
  //[{id: asdfasdf, feild: value}]
  axios.post("https://utils.polka.ph/v4backend/airtable-api.php", {statement: "kiosk-order-update", payload: data }).then( (res) =>{
    console.log(res)
    window.location.href = redirect
    //console.log(redirect)
  })
}


function shipmenthChange(val){
    if (val === "pickup") {
      street.value = "5th Floor SM North EDSA Annex Cyberzone"
      brgy.value = "Santo Cristo"
      city.value = "Quezon City"
      prov.value = "Metro Manila"
      zip.value = "1105"
      notes.value = "POLKA.PH near OCTAGON"
    } else if (val === "delivery") {
      street.value = ""
      brgy.value = ""
      city.value = ""
      prov.value = ""
      zip.value = ""
      notes.value = ""
    }
}

</script>

<style scoped>
.redbar {
  height: 60px;
  background-color: #ff004a;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.sticky-total {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 30;
  background-color: white;
  border-bottom: 1px solid black;
}

#mobile-container::before {
  content: "+63";
  position: absolute;
  padding: 0 12px;
  display:inline-block;
  vertical-align:middle;
  line-height: 40px;
  height: 40px;
  background-color: #EEEEEE;
  border-radius: 50px 0 0 50px;
  border: 1px solid black;
}

.flex-container {
	display: flex;
	gap: 0.7rem;
  margin-bottom: 1.1rem;
  height: 40px;
}

.flex-container .input-short {
	flex-grow: 1;
  width: 100%;
  padding: 6px 12px;
  border-radius: 50px;
  border: 1px solid black;
  height: 100%;
}

.payOption {
	border-radius: 20px;
  border: 2px solid gray;
}

#mobile {
	padding-left: 60px;
}

.flex-container .input-long {
  width: 100%;
  padding: 6px 12px;
  border-radius: 50px;
  border: 1px solid black;
  height: 100%;
}

input {
	height: 28px;
  line-height: 28px;
}


.submit-button {
  width: 100%;
	padding: 6px 12px;
  border-radius: 50px;
  border: 1px solid #ff004a;
  background-color: #ff004a;
  margin: 10px 0 0;
  cursor: pointer;
  color: white;
  font-weight: 600;
  height: 40px;
}

select {
  padding: 10px;
  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

/* For IE (thanks to @SaiManoj) */
select::-ms-expand {
  display: none;
}

table, th, td {
  border-collapse: collapse;
  line-height: 1.6;
}

td {
  padding-top: 9px;
  padding-bottom: 9px;
}

.top-wrapper {
  background-color: #FFF1F2;
  padding-top: 20px;
  padding-bottom: 80px;
  margin:0;
}

.polkalogo {
  margin-left:16px;
  height: 30px;
  margin-top:15px;
}

.white-section {
  background-color: white;
  margin: 60px max(calc(50% - 405px), 20px) 60px;
  border-radius: 20px;
  padding: 20px 20px 40px;
  box-shadow: 0 3px 9px rgb(0 0 0 / 10%);
  max-width: 810px;
}

.half-container {
	width: 49%;
  display:inline-block;
  vertical-align: top;
  height: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.pimage {
  margin-left:-15px;
}


@media only screen and (max-width: 576px) {
   .white-section {
       border-radius: 0 0 20px 20px;
       margin: 60px 0 60px 0;
   }
}

@media only screen and (max-width: 576px) {
  .half-container {
    width: 100%;
  }
 
  .pname {
    margin-top:12px;
  }
 
  .pimage {
    margin-left:0;
  }
 
  .half-container {
    position: -webkit-relative;
    position: relative;
    top: 0;
  }
}

.pimage {
  display: inline-block;
  width: 100%;
}

.pname {
  display: inline-block;
  font-weight: 600;
  font-size: 26px;
}

.notice {
	background-color: #FFFCF0;
  border-left: 3px solid #FFD66C;
  padding: 18px 16px;
  border-radius: 0 16px 16px 0;
  margin-top: 26px;
}


/* The container */
.payOptionsContainer {

  display: block;
  position: relative;
  padding: 12px 18px 6px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
  
}

/* Hide the browser's default radio button */
.payOptionsContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% - 4px);
  background-color: transparent;
  border-radius: 16px;
  border: 2px solid rgb(206, 212, 218);
}

/* On mouse-over, add a grey background color */
.payOptionsContainer:hover input ~ .checkmark {

}

/* When the radio button is checked, add a blue background */
.payOptionsContainer input:checked ~ .checkmark {
  border: 2px solid #ff004a;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.payOptionsContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.payOptionsContainer .checkmark:after {
  right: 20px;
  top: 12px;
  transform: rotate(45deg);
  height: 13px;
  width: 6px;
  border-bottom: 3.5px solid #ff004a;
  border-right: 3.5px solid #ff004a;
}

.payHeadline {
	font-weight: 500;
    color: #ff004a;
    font-size: 18px;
}

.payImage {
	max-height: 42px;
    padding: 16px 0 4px;
    max-width: 100%;
}

ul {
	margin: 0;
    padding-left: 30px;
    line-height: 1.75;
}

li {
	margin: 0.5rem 0;
}

h2 {
	margin: 30px 0 12px;
  font-size: 20px; 
  font-weight: 600;
}


/* The container */
.agree-container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

/* Hide the browser's default checkbox */
.agree-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  font-size: 16px;
}

/* Create a custom checkbox */
.checkBox {
  position: absolute;
  top: 4px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: white;
  border: 1px solid #666666;
    border-radius: 3px;
}

/* On mouse-over, do something */
/*.agree-container:hover input ~ .checkBox {

}*/

/* When the checkbox is checked, add a red background */
.agree-container input:checked ~ .checkBox {
  background-color: #FF004a;
  border: 1px solid #ff004a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkBox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.agree-container input:checked ~ .checkBox:after {
  display: block;
}

/* Style the checkmark/indicator */
.agree-container .checkBox:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

</style>

